export const COLUMN_TYPE_STRING = 'string';
export const COLUMN_TYPE_NUMBER = 'number';
export const COLUMN_TYPE_DATE = 'date';
export const COLUMN_TYPE_IMAGE = 'image';
export const COLUMN_TYPE_BOOL = 'bool';
export const COLUMN_TYPE_RICH_TEXT = 'richText';

export const DATA_SOURCE_STATIC = 'static';
export const DATA_SOURCE_DYNAMIC = 'dynamic';

export const PAGINATION_TYPE_NONE = 'normal';
export const PAGINATION_TYPE_PAGES = 'pagination';
export const PAGINATION_TYPE_SCROLL = 'virtual';

export const USER_SELECTION_TYPE_NONE = 'none';
export const USER_SELECTION_TYPE_ROW = 'row';
export const USER_SELECTION_TYPE_CELL = 'cell';

export const COLUMN_LAYOUT_MANUAL = 'manual';
export const COLUMN_LAYOUT_EQUAL = 'equal';

export const LINK_TARGET_BLANK = '_blank';
export const LINK_TARGET_SELF = '_self';

export const HEADER_COLUMN_NONE = 'none';
export const HEADER_COLUMN_FIRST = 'first';
export const HEADER_COLUMN_LAST = 'last';

export const BOOL_CELL_TEXT_TRUE = 'Yes';
export const BOOL_CELL_TEXT_FALSE = 'No';

export const DEFAULT_COLUMN_WIDTH = 200;
