import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import {
  changePropsSDKFactory,
  createRequiredPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
  IRatingsInputSDK,
  IRatingsInputOwnSDKFactory,
} from '../RatingsInput.types';
import {
  composeValidators,
  createInputValidator,
  InputValidator,
} from '../../../core/corvid/inputUtils';

const ratingsInputValidator: InputValidator<
  IRatingsInputProps,
  IRatingsInputImperativeActions
> = createInputValidator(
  composeValidators<IRatingsInputProps>([
    // todo validator
  ]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  ratingsInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  ratingsInputValidator,
);

const createSdkFactory: IRatingsInputOwnSDKFactory = ({
  setProps,
  props,
  registerEvent,
}) => ({
  get value() {
    return props.rating;
  },
  set value(value) {
    // todo
    setProps({ rating: value });
  },
  onIconMouseIn(handler: () => void) {
    // todo
    registerEvent('iconMouseIn', handler);
  },
});

const ratingsInput = withValidation(createSdkFactory, {
  type: ['object'],
  properties: {
    value: {
      type: ['integer', 'nil'],
      maximum: 5,
      minimum: 1,
    },
    onIconMouseIn: {
      type: ['function'],
      args: [{ type: ['function'] }],
    },
  },
});

export const sdk = composeSDKFactories<IRatingsInputProps, IRatingsInputSDK>(
  ratingsInput,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  elementPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  focusPropsSDKFactory,
  changePropsSDKFactory,
);
