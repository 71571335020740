import { createMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';

export type LinkTarget = '_self' | '_blank';
export type TpaImage = {
  type: string;
  uri: string;
  width: number;
  height: number;
  alt?: string;
  description?: string;
  link?: any;
  title?: string;
  src?: string;
  linkType?: string;
  target?: LinkTarget;
};

export function convertImagesToUserModel(
  images: Array<TpaImage>,
  linkUtils: any,
) {
  return images.map((image: TpaImage) => {
    const {
      type,
      title,
      width,
      height,
      uri,
      alt,
      description,
      link,
      src,
    } = image;
    const mediaSrc = createMediaSrc({
      mediaId: uri || src,
      type: type.toLowerCase(),
      title,
      width,
      height,
    });

    let resolvedLink;
    if (link) {
      try {
        resolvedLink = linkUtils.getLinkUrlFromDataItem(link);
      } catch (e) {
        resolvedLink = link.href;
      }
    }

    return {
      type: type.charAt(0).toUpperCase() + type.slice(1).toLowerCase(),
      ...(description && { description }),
      alt: alt || '',
      ...(title && { title }),
      ...(height && { height }),
      ...(width && { width }),
      ...(link && {
        link: resolvedLink,
      }),
      ...(link && link.target && { target: link.target }),
      src: mediaSrc.item || mediaSrc.error || '',
    };
  });
}
