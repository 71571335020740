import {
  withValidation,
  composeSDKFactories,
  assert,
} from '@wix/editor-elements-corvid-utils';
import {
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  clickPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { IGridOwnSDKFactory, IGridProps, IGridSDK } from '../Grid.types';
import { DATA_SOURCE_STATIC } from '../constants';

const ownSDKFactory: IGridOwnSDKFactory = ({ setProps, props, metaData }) => {
  const type = '$w.Table';

  return {
    set pagination(value) {
      setProps({ pagination: { ...value } });
    },
    get pagination() {
      return { ...props.pagination };
    },
    set columns(value) {
      const columns = assert.isArray(value)
        ? value.map(column => ({ ...{ visible: true }, ...column }))
        : [];

      setProps({ columns });
    },
    get columns() {
      return props.columns.map(column => ({ ...column }));
    },
    set rows(value) {
      const rows = assert.isArray(value) ? value.map(row => ({ ...row })) : [];
      const dataSource = DATA_SOURCE_STATIC;

      // TODO: Validate dates, process links and images

      setProps({ rows, dataSource });
    },
    get rows() {
      return props.rows.map(row => ({ ...row }));
    },
    get type() {
      return type;
    },
    toJSON() {
      const { pagination, columns, rows } = props;
      return {
        ...toJSONBase(metaData),
        type,
        pagination,
        columns,
        rows,
      };
    },
  };
};

const ownSDKFactoryWithValidation = withValidation(ownSDKFactory, {
  type: ['object'],
  properties: {
    pagination: {
      type: ['object'],
      properties: {
        type: {
          type: ['string'],
          enum: ['normal', 'pagination', 'virtual'],
        },
        rowsPerPage: {
          type: ['number'],
        },
      },
      required: ['type'],
    },
    columns: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
        properties: {
          id: { type: ['string'] },
        },
        required: ['id'],
      },
    },
    rows: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
      },
    },
  },
});

export const sdk = composeSDKFactories<IGridProps, IGridSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
  ownSDKFactoryWithValidation,
);
